import { Component } from 'react';

import styles from "./NotFound.module.scss";

export default class NotFound extends Component {
  render() {
    return (
      <div className={'container'}>
        <section className={'row'}>
          <div className={styles.container}>
            <div className={styles.notfound}>
              <div className={styles.notfound404}>
                <h1>404</h1>
              </div>
              <h2>Przepraszamy, strona nie istnieje</h2>
              <p>Wróć na stronę główną</p>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
