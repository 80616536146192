import ApiHandler from "./ApiHandler";

export default class PostsHandler {
  constructor() {
    this.api = new ApiHandler();
  }

  async items(page, perPage, tags) {
    return await this.api.getPosts(page, perPage, tags);
  }

  async item(slug) {
    return await this.api.getPost(slug)
  }

  async promotes(count, currentId) {
    const PROMOTE_CATEGORY_ID = 6;

    return await this.api.getPosts(1, count, [], [PROMOTE_CATEGORY_ID], [currentId]);
  }
}