import SendGridApiHandler from "./SendGridApiHandler";

export default class ContactHandler {
  constructor() {
    this.api = new SendGridApiHandler()
  }

  async post(fields, withNewsletter) {
    return await this.api.saveContact24(fields, withNewsletter);
  }
}