import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

import style from './Footer.module.scss';
import * as Scroll from "react-scroll";

const Footer = () => {
  const Element  = Scroll.Element
  const facebookPage = 'https://www.facebook.com/TrenerRogala';
  const youtubePage = 'https://www.youtube.com/channel/UCVGStdO3uX9nZS-YAUOfsXA';
  const linkedinPage = 'https://www.linkedin.com/in/damianrogala/';
  const socialIcons = [
    {icon: faFacebook, link: facebookPage, style: style.facebook},
    {icon: faLinkedin, link: linkedinPage, style: style.linkedin},
    {icon: faYoutube, link: youtubePage, style: style.youtube},
  ]

  return (
    <>
      <Element name="Footer"></Element>
      <div className="font-corbel container pb-6">
        <h4 className={`text-center py-6 ${style.title}`}>Kontakt</h4>
        <div className="row">
          <div className="col-xl-4 offset-xl-4">
            <div className="d-flex justify-content-around">
              <div className={style.contact}>
                Damian Rogala<br/>
                <br/>
                <a href="tel:+48 691 191 710" className={style.link}>+48 691 191 710</a><br/>
                <a href="mailto:kontakt@damianrogala.com" className={style.link}>kontakt@damianrogala.com</a>
              </div>
              <div className={style.socialIcon}>
                {socialIcons.map((item, key) =>
                  <a key={key} href={item.link} target="_blank" rel="noreferrer" className={item.style}>
                    <FontAwesomeIcon icon={item.icon} size="lg" className="ml-4" fixedWidth />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <small className="text-muted">
            Wykonanie: <a className="text-muted" href="https://novi-it.pl" target="_blank" rel="noreferrer">novi-IT</a>
          </small>
        </div>
        <div className="text-right">
          <small className="text-muted">
            Projekt: <a className="text-muted" href="https://linkedin.com/in/natalia-suwara-895809117" target="_blank" rel="noreferrer">Natalia Suwara</a>
          </small>
        </div>
      </div>
    </>
  );
}

export default Footer