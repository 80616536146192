import React, { Component } from "react";
import { Redirect } from 'react-router'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import PostsHandler from "../../services/PostsHandler";
import ContactForm from "../../components/ContactForm/ContactForm";
import styles from "../Blog/Blog.module.scss";
import Post from "../../components/Post/Post";
import rogal from "../Homepage/rogal.svg";

export default class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slug: props.match.params.slug,
      loading: true,
      post: {},
      promote:[]
    };
  }

  async fetchPost() {
    this.setState({
      loading: true
    })
    const postsHandler = new PostsHandler();
    const post = await postsHandler.item(this.state.slug);
    let promotes = [];
    if (undefined !== post) {
      promotes = await postsHandler.promotes(3, post.id);
    }

    this.setState({
      post,
      loading: false,
      promotes
    })
  }

  async componentDidMount() {
    await this.fetchPost();
  }

  renderPost = () => {
    return (
      <>
        <div className={'offset-xl-3 col-xl-6'}>
          <h3 className={'text-center pb-xl-5'} dangerouslySetInnerHTML={{__html: this.state.post.title}}></h3>
          <div dangerouslySetInnerHTML={{__html: this.state.post.content}}></div>
        </div>
        {this.state.promotes.length > 0 ? this.renderPromotes() : ''}
      </>
    )
  }

  renderPromotes = () => {
    return (
      <>
        <div className="col-12 py-6 text-center">
          <img src={rogal} alt={''} />
        </div>
        <div className="col-12">
          <h5 className="text-center font-corbel font-weight-bold">Polecam również:</h5>
        </div>
        <div className={'offset-xl-1 col-xl-10 py-6'}>
          <div className="row">
            {this.state.promotes.map((post, index) => {
              return (
                <div className="col-xl-4" key={post.id} >
                  <Post post={post} column={index % 3} />
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  renderLoading = () => {
    return (
      <div className={`col-12 d-flex justify-content-center align-items-center text-rogala ${styles.loading}`}>
        <FontAwesomeIcon icon={ faSpinner } size="2x" pulse fixedWidth />
      </div>
    )
  }

  render() {
    return (
      <div className={'container'}>
        <section className={'row'}>
          {this.state.loading
              ? this.renderLoading()
              : (undefined !== this.state.post ? this.renderPost() : <Redirect to="/not-found" />)
          }
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <ContactForm theme="blue" />
          </div>
        </section>
      </div>
    )
  }
}
