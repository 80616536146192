import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText
} from 'reactstrap';
import * as Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faBars } from '@fortawesome/free-solid-svg-icons'

import logo from './logo.svg';
import './Header.scss';

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const scrollTo = (name) => {
    Scroll.scroller.scrollTo(name, {smooth: true})
  }
  const isActive = path => path === pathname;

  return (
    <div className="container">
      <div className="offset-xl-1 col-xl-10">
        <Navbar light expand="xl">
          <NavbarBrand href="/">
            <img src={logo} className="d-inpline-block align-top" alt="" loading="lazy"/>
          </NavbarBrand>
          <div>
            <button onClick={() => {window.location = 'tel:+48 691 191 710'}}
                    type="button"
                    className="btn btn-outline-rogala shadow-none d-xl-none ml-2">
              <FontAwesomeIcon icon={ faPhone } fixedWidth />
            </button>
            <button onClick={toggle} type="button" className="btn btn-outline-rogala shadow-none d-xl-none ml-2">
              <FontAwesomeIcon icon={ faBars } fixedWidth />
            </button>
          </div>
          <Collapse isOpen={isOpen} navbar className="d-xl-flex flex-xl-column">
            <Nav navbar className="ml-auto">
              <NavItem className={isActive('/') ? 'active' : ''}>
                <NavLink href="/">O mnie</NavLink>
              </NavItem>
              <NavItem className={isActive('/for-business') ? 'active' : ''}>
                <NavLink href="/for-business">Oferta dla firm</NavLink>
              </NavItem>
              <NavItem>
                <NavLink role="button" onClick={() => scrollTo('ContactForm')}>Bezpłatna konsultacja</NavLink>
              </NavItem>
              <NavItem className={isActive('/blog') ? 'active' : ''}>
                <NavLink href="/blog">Blog</NavLink>
              </NavItem>
              <NavItem>
                <NavLink role="button" onClick={() => scrollTo('Footer')}>Kontakt</NavLink>
              </NavItem>
            </Nav>
            <NavbarText className={'ml-auto'}>
              <NavLink href="tel:+48 691 191 710">+48 691 191 710</NavLink>
            </NavbarText>
          </Collapse>
        </Navbar>
      </div>
    </div>
  )
}

export default Header;