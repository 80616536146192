import React, { useState } from 'react';
import { useSwipeable } from "react-swipeable";
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
import './Testimonials.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import clip from './clip.svg';

const Testimonials = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const items = props.testimonials || [];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        tag="div"
        key={item.slug}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div dangerouslySetInnerHTML={{__html: item.title}} className="title"></div>
        <div dangerouslySetInnerHTML={{__html: item.content}} className="content"></div>
      </CarouselItem>
    );
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => previous(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const renderTestimonials = () => {
    return (
      <>
        <div className="col-12">
          <h5 className="text-center font-corbel font-weight-bold">Referencje moich klientów</h5>
        </div>
        <div className="w-100"></div>
        <div className="testimonials" {...handlers}>
          <div className="wrapper">
            <div className="icons d-flex justify-content-between align-items-center">
              <FontAwesomeIcon className="quote" icon={ faQuoteRight } />
              <div>
                <img src={clip} alt="" />
              </div>
            </div>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              {slides}
              <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            </Carousel>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {items.length > 0 ? renderTestimonials() : ''}
    </>
  );
}

export default Testimonials;