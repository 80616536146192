import React, { Component } from 'react';
import { Link } from "react-router-dom";

import banner from './banner.png';
import bannerMobile from './banner-mobile.png';
import styles from './Homepage.module.scss';
import aboutMe1 from './about-me-1.png';
import aboutMe2 from './about-me-2.png';
import rogal from './rogal.svg';

import Testimonials from "../../components/Testimonials/Testimonials";
import ContactForm from "../../components/ContactForm/ContactForm";
import Numbers from "../../components/Numbers/Numbers";
import TestimonialsHandler from "../../services/TestimonialsHandler";

export default class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonials: []
    };
  }

  async fetchData() {
    const testimonialsHandler = new TestimonialsHandler();
    const items = await testimonialsHandler.items();
    this.setState({
      testimonials: items,
    });
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    return (
      <div className="container">
        <div className="row font-corbel">
          <div className={'d-none d-xl-flex justify-content-xl-center offset-xl-1 col-xl-10 ' + styles.bannerWrapper}>
            <img className="mw-100" src={banner} alt="" />
            <div className={styles.textWrapper}>
            <div className={'container ' + styles.textContainer}>
              <div className="offset-xl-2 col-xl-4">
                <b>„Ludzie sukcesu szukają rozwiązań, pozostali szukają wymówek”.</b><br/>
                <br/>
                Znajdźmy wspólnie najlepsze rozwiązanie dla Twojej firmy i&nbsp;skróćmy drogę do osiągania zuchwałych celów.<br />
                <br />
                <b>#działanierządzi</b>
              </div>
              <div className="offset-xl-2 col-xl-3 pt-xl-4">
                <Link to={'/for-business'} className={`btn btn-rogala btn-block ${styles.buttonMeetUp}`}>
                  POZNAJMY SIĘ!
                </Link>
              </div>
            </div>
            </div>
          </div>
          <div className={`d-block d-xl-none d-xxl-none col-12 ${styles.textContainer}`}>
            <div className="row">
              <div className={`col-12 ${styles.bannerMobile}`} style={{backgroundImage: `url(${bannerMobile})`}}></div>
              <div className="col-12 py-4 text-center">
                <b><big>„Ludzie sukcesu szukają rozwiązań, pozostali szukają wymówek”.</big></b><br/>
                <br/>
                Znajdźmy wspólnie najlepsze rozwiązanie dla Twojej firmy i&nbsp;skróćmy drogę do osiągania zuchwałych celów.<br />
                <br />
                <b>#działanierządzi</b>
              </div>
              <div className="col-12 py-2">
                <Link to={'/for-business'} className="btn btn-rogala btn-block">POZNAJMY SIĘ!</Link>
              </div>
            </div>
          </div>
        </div>
        <h3 className="text-center pt-6 pb-xl-5 pb-3 font-corbel">O mnie</h3>
        <section className="row">
          <div className={'offset-1 col-10 offset-xl-3 col-xl-6 text-left text-xl-center font-corbel ' + styles.aboutMe}>
            Cześć! Pochodzę z Bełżca, małej miejscowości przy granicy z Ukrainą. W 2015 roku przeprowadziłem się do dużego miasta, aby realizować swoje marzenia. Po początkowych trudnościach, los się do mnie uśmiechnął i trafiłem do miejsca, w którym mogłem się rozwijać, poznałem ludzi, którzy dzielili się ze mną doświadczeniem i realizowałem zadania, które budowały moje poczucie sprawczości.
          </div>
          <div className="w-100 pt-5"></div>
          <div className="offset-xl-3 col-xl-5 text-left">
            <div className={'d-flex flex-row justify-content-start align-items-stretch ' + styles.textWithScroll}>
              <div className={`pl-5 pl-xl-5 ml-4 ml-xl-7 ${styles.scroll}`}>
                <div className={styles.square}></div>
              </div>
              <div className="font-corbel pr-4 pr-xl-0">
                Dzięki ciągłemu podejmowaniu nowych wyzwań poszerzaniu swojej wiedzy i czerpaniu od bardziej doświadczonych osób moja kariera zawodowa nabrała niesamowitego tempa.<br/>
                <br/>
                W 6 miesięcy ze „słuchawki” awansowałem na stanowisko trenera sprzedaży, w tym czasie pobiłem najważniejsze rekordy sprzedażowe. 2 lata później już na stanowisku menadżera ds. wsparcia sprzedaży, świętowałem wspólnie ze swoim podopiecznym Tytuł  Telemarketera Roku 2019, najważniejszego wyróżnienia dla sprzedawcy telefonicznego w Polsce.<br/>
                <br/>
                Po zdobyciu doświadczenia w zarządzaniu zespołem sprzedażowym w Kancelarii Prawno Finansowej, w lipcu 2020 roku zdecydowałem się na otwarcie własnej firmy szkoleniowej. Cel, jakim był rozwój swojego biznesu z datą realizacji grudzień 2030, zrealizowałem ponad 10 lat przed czasem. Wyznaczanie i zapisywanie celów, naprawdę działa cuda :)
              </div>
            </div>
          </div>
          <div className="w-100 pt-5 pt-xl-7"></div>
          <div className="col-xl-12 text-center">
            <div style={{backgroundImage: `url(${aboutMe1}`}} className={styles.aboutMeImage} />
          </div>
          <div className="w-100 pt-5"></div>
          <div className="offset-xl-3 col-xl-5 text-left">
            <div className={'d-flex flex-row justify-content-start align-items-stretch ' + styles.textWithScroll}>
              <div className={`pl-5 pl-xl-5 ml-4 ml-xl-7 ${styles.scroll}`}>
                <div className={styles.square}></div>
              </div>
              <div className="font-corbel pr-4 pr-xl-0">
                Ale moje życie to nie tylko biznes, prywatnie jestem ojcem wspaniałego chłopca i mężem cudownej kobiety. Moje hobby to gra w szachy i walka z partnerem na macie trenując brazylijskie jujitsu.<br/>
                <br/>
                <br/>
                <b><i>Moim zdaniem, podejmując przemyślane decyzje, następnie realizując zaplanowane działania oraz skrupulatnie analizując rezultaty, jesteśmy w stanie osiągnąć bardzo dużo w stosunkowo niewielkim czasie.</i></b><br/>
                <br/>
                <br/>
                Moją zawodową misją jest dzielenie się wiedzą i doświadczeniem, jeżeli mi oraz moim Klientom pomaga w realizacji zuchwałych celów, pomoże i Tobie! Napisz do mnie albo zadzwoń, sprawdzimy, jak mogę Ci pomóc.
              </div>
            </div>
          </div>
          <div className="w-100 pt-5"></div>
          <div className="col-xl-12 text-center">
            <div style={{backgroundImage: `url(${aboutMe2}`}} className={styles.aboutMeImage} />
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-12 text-center">
            <img src={rogal} alt={''} />
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <Testimonials testimonials={this.state.testimonials} />
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <Numbers />
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <ContactForm />
          </div>
        </section>
      </div>
    )
  }
}
