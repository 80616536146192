import SendGridApiHandler from "./SendGridApiHandler";

export default class ToDoListHandler {
  constructor() {
    this.api = new SendGridApiHandler()
  }

  async post(fields, withNewsletter) {
    return await this.api.saveToDo(fields, withNewsletter);
  }
}