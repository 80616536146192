import axios from 'axios';
import jwt from 'jsonwebtoken';

export default class SendGridApiHandler {
  constructor() {
    this.api = axios.create({
      baseURL: '/api',
      headers: {'Content-type': 'application/json'}
    });
  }

  async putContact(fields, listIds) {
    const KEY = process.env.REACT_APP_JWT_TOKEN;
    const token = jwt.sign({}, KEY, { expiresIn: 2 * 60 });
    let [firstName, ...lastNames] = fields.name.split(' ');

    return await this.api.put('/marketing/contacts', {
      "list_ids": listIds,
      "contacts": [
        {
          "first_name": firstName,
          "last_name": lastNames.join(' '),
          "email": fields.email,
          "custom_fields": {
            "w1_T": fields.phone
          }
        }
      ]
    }, {headers: {'Authorization': `Bearer ${token}`}})
  }

  async postMail(fields) {
    const KEY = process.env.REACT_APP_JWT_TOKEN;
    const token = jwt.sign({}, KEY, { expiresIn: 2 * 60 });
    return await this.api.post('/mail/send', {
      "personalizations": [
        {
          "to": [
            {
              "email": process.env.REACT_APP_NOTIFICATION_EMAIL,
              "name": process.env.REACT_APP_NOTIFICATION_NAME
            }
          ],
          "dynamic_template_data": {
            "name": fields.name,
            "email": fields.email,
            "phone": fields.phone
          },
          "subject": process.env.REACT_APP_NOTIFICATION_SUBJECT
        }
      ],
      "from": {
        "email": process.env.REACT_APP_SENDER_EMAIL
      },
      "template_id": process.env.REACT_APP_TEMPLATE_ID
    }, {headers: {'Authorization': `Bearer ${token}`}})
  }

  async saveContact24(fields, withNewsletter) {
    let listIds = [process.env.REACT_APP_CONTACT_24h_ID];
    if (withNewsletter) {
      listIds.push(process.env.REACT_APP_NEWSLETTER_ID);
    }
    await this.postMail(fields);

    return await this.putContact(fields, listIds)
  }

  async saveToDo(fields, withNewsletter) {
    let listIds = [process.env.REACT_APP_TODO_ID];
    if (withNewsletter) {
      listIds.push(process.env.REACT_APP_NEWSLETTER_ID);
    }
    return await this.putContact(fields, listIds)
  }
}