import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faArrowRight, faArrowLeft, faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import TrackVisibility from 'react-on-screen';
import ContactForm from "../../components/ContactForm/ContactForm";
import TagsHandler from "../../services/TagsHandler";
import PostsHandler from "../../services/PostsHandler";

import styles from "./Blog.module.scss";
import banner from "./banner.svg";
import {Button} from "reactstrap";

import Post from "../../components/Post/Post";

export default class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      filterBy: [],
      tags: [],
      images: {},
      loading: false,
      page: 1,
      perPage: 9,
      pageCount: 0
    };
  }

  async fetchTags() {
    const tagsHandler = new TagsHandler()
    this.setState({
      tags: await tagsHandler.items(),
    });
  }

  async fetchData() {
    this.setState({
      loading: true,
    });
    const postsHandler = new PostsHandler();
    const items = await postsHandler.items(this.state.page, this.state.perPage, this.state.filterBy);
    this.setState({
      posts: items,
      loading: false,
    });
  }

  async componentDidMount() {
    await this.fetchTags();
    await this.fetchData();
  }

  handlePageClick = (data) => {
    this.setState({ page: data.selected + 1 }, async () => {
      await this.fetchData();
    });
  };

  handleTagClick = (item) => {
    this.setState(prevState => {
      const filterBy = prevState.filterBy.includes(item.id)
        ? prevState.filterBy.filter(id => id !== item.id)
        : [...prevState.filterBy, item.id]

      return {filterBy}
    }, async () => await this.fetchData())
  }

  renderList = () => {
    return (
      <>
        <div className="row">
          {this.state.posts.map((post, index) => {
            return (
              <div className="col-xl-4" key={post.id} >
                <TrackVisibility once partialVisibility offset={1000}>
                  <Post post={post} column={index % 3} />
                </TrackVisibility>
              </div>
            )
          })}
        </div>
        {this.state.pageCount > 1 ? this.renderPaginate() : ''}
      </>
    )
  }

  renderPaginate = () => {
    return (
      <div className="row">
        <div className="col-xl-4 offset-xl-8">
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={ faArrowLeft } fixedWidth />}
            nextLabel={<FontAwesomeIcon icon={ faArrowRight } fixedWidth />}
            breakLabel={<FontAwesomeIcon icon={ faEllipsisH } fixedWidth />}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            initialPage={this.state.page -  1}
            disableInitialCallback={true}
            onPageChange={this.handlePageClick}
            containerClassName={styles.pagination}
            pageClassName={styles.page}
            pageLinkClassName={`text-decoration-none ${styles.link}`}
            previousClassName={`${styles.page} ${styles.previous}`}
            previousLinkClassName={`text-decoration-none ${styles.link}`}
            nextClassName={`${styles.page} ${styles.next}`}
            nextLinkClassName={`text-decoration-none ${styles.link}`}
            breakClassName={`${styles.page} ${styles.breakMe}`}
            breakLinkClassName={`text-decoration-none ${styles.link}`}
            activeClassName={styles.active}
            disabledClassName={styles.disable}
          />
        </div>
      </div>
    )
  }

  renderLoading = () => {
    return (
      <div className={`col-12 d-flex justify-content-center align-items-center text-rogala ${styles.loading}`}>
        <FontAwesomeIcon icon={ faSpinner } size="2x" pulse fixedWidth />
      </div>
    )
  }

  renderTags = () => {
    return (
      <>
        {this.state.tags.map((item) => {
          const isActive = this.state.filterBy.includes(item.id);
          return (
            <div className="px-3 py-1">
              <Button key={item.id}
                      color={isActive ? 'rogala' : 'rogala-blue'}
                      className={styles.tagButton}
                      onClick={() => this.handleTagClick(item)}>
                {item.name}
              </Button>
            </div>
          )
        })}
      </>
    )
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="offset-xl-1 col-xl-10">
            <div className={styles.banner} style={{backgroundImage: `url(${banner})`}} alt=""/>
          </div>
        </div>
        <h4 className="text-center pt-6">Witaj na moim blogu. Znajdziesz tutaj:</h4>
        <section className="row">
          <div className="py-4 py-xl-5 offset-xl-1 col-xl-10 d-flex flex-row flex-wrap justify-content-center">
            {this.renderTags()}
          </div>
          <div className="w-100"></div>
          <div className="offset-xl-1 col-xl-10">
            {this.state.loading ? this.renderLoading() : this.renderList()}
          </div>
          <div className="w-100 pt-xl-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <ContactForm theme="blue" />
          </div>
        </section>
      </div>
    )
  }
}