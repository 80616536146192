import React, { Component } from 'react';

import styles from "../ForBusiness/ForBusiness.module.scss";
import banner from "./banner.png";
import rogala from './rogala.png'
import Testimonials from "../../components/Testimonials/Testimonials";
import Numbers from "../../components/Numbers/Numbers";
import ContactForm from "../../components/ContactForm/ContactForm";
import EffectivenessPillar from "../../components/EffectivenessPillar/EffectivenessPillar";
import Offer from "../../components/Offer/Offer";
import TestimonialsHandler from "../../services/TestimonialsHandler";

export default class ForBusiness extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonials: []
    };
  }

  async fetchData() {
    const testimonialsHandler = new TestimonialsHandler();
    const items = await testimonialsHandler.items();
    this.setState({
      testimonials: items,
    });
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    return (
      <div className="container">
        <div className={`d-none d-xl-block font-corbel row ${styles.banner}`}>
          <div className="offset-xl-1 col-xl-10 d-flex justify-content-between align-items-center h-100">
            <div className="col-xl-3">
              <span className={styles.title}>Firmy, przedsiębiorców oraz korporacje</span><br/>
              <br/>
              wykorzystujące w swoim modelu biznesowym telefon jako narzędzie do realizacji celów sprzedażowych lub marketingowych zachęcam do poznania moich kompetencji oraz zakresu oferowanych usług.
            </div>
            <img className="mh-100" src={banner} alt="" />
          </div>
        </div>
        <div className="d-block d-xl-none d-xxl-none font-corbel row">
          <div className="col-12">
            <div className={styles.bannerMobile} style={{backgroundImage: `url(${banner})`}}></div>
            <h4 className="text-center pt-5">Firmy, przedsiębiorców oraz korporacje</h4>
            <h6 className="text-center font-weight-normal">wykorzystujące swoim modelu biznesowym telefon jako narzędzie do realizacji celów sprzedażowych lub marketingowych zachęcam do poznania moich kompetencji oraz zakresu oferowanych usług.</h6>
          </div>
        </div>
        <h4 className="text-center font-corbel font-weight-bold pt-6">Oferta dla firm</h4>
        <h6 className="text-center font-weight-normal">SWOJĄ SKUTECZNOŚĆ OPIERAM NA 4 FILARACH:</h6>

        <section className="row">
          <div className="col-xl-8 offset-xl-2">
            <div className="row">
              <div className="pt-xl-5 col-xl-6">
                <EffectivenessPillar
                  number="I"
                  title="Studia Coach i trener Partner w Rozwoju, na Wyższej Szkole Europejskiej w Krakowie"
                  list={{title: "Dzięki którym mogę Ci dać:", items: [
                    'Coaching w standardach ICF',
                    'Angażujące Warsztaty',
                    'Efektywne Szkolenia'
                  ]}}
                />
              </div>
              <div className="pt-xl-5 col-xl-6">
                <EffectivenessPillar
                  number="II"
                  title="Studia Psychologia w Biznesie, na Wyższej Szkole Europejskiej w Krakowie"
                  list={{title: "Dzięki którym mogę Ci dać:", items: [
                      'Badanie oraz zmianę kultury organizacyjnej',
                      'Holistyczne wskazówki, jak szybko poprawić działanie firmy',
                      'Naukowe strategie, jak skutecznie zarządzać zespołami'
                    ]}}
                />
              </div>
              <div className="pt-xl-5 col-xl-6">
                <EffectivenessPillar
                  number="III"
                  title="Studia Managerskie, Na Wyższej Szkole Europejskiej w Krakowie"
                  list={{title: "Dzięki którym mogę Ci dać:", items: [
                      'Sprawdzone modele, jak budować zespoły',
                      'Praktyczne narzędzia, poprawiające efektywność',
                      'Wiedzę oraz doświadczenie, jak wprowadzać zmiany'
                    ]}}
                />
              </div>
              <div className="pt-xl-5 col-xl-6">
                <EffectivenessPillar
                  number="IV"
                  title="Trening Neurolingwistycznego programowania u Marka Rożalskiego poziom praktyk"
                  list={{title: "Dzięki którym mogę Ci dać:", items: [
                      'Niekonwencjonalne sposoby na motywowanie tych niezmotywowanych',
                      'Skuteczne modele efektywnej komunikacji',
                      'Zwiększenie skuteczności działów obsługi Klientów'
                    ]}}
                />
              </div>
            </div>
          </div>
          <div className="w-100 pt-6"></div>
          <div className={`col-xl-12 font-corbel text-center ${styles.offerCaption}`}>
            Nabyte dzięki temu doświadczenie, jest bezcenną wartością dla moich Klientów.<br/>
            Sprawdź jakie Twoje potrzeby, mogę zaspokoić.
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-4 offset-xl-2">
            <div className={styles.offerImage} style={{backgroundImage: `url(${rogala})`}}></div>
          </div>
          <div className={`col-xl-4 ${styles.offer}`}>
            <div className="font-corbel">
              <b>Odpowiadam na takie potrzeby jak:</b>
              <ul className="pt-4">
                <li><b>lepsze wyniki działu sprzedaży,</b> aby wywindować rentowność działu na nowy wysoki poziom,</li>
                <li><b>zbudowanie zgranego i efektywnego zespołu,</b> który sprawnie będzie realizować powierzone mu zadania,</li>
                <li>rozwój kadry kierowniczej, by mogła skutecznie budować i zarządzać podległymi zespołami,</li>
                <li>rozwinięcie kompetencji i umiejętności pracowników o techniki sprzedażowe oraz <b>narzędzia NLP,</b> aby ułatwić im wykonywanie swojej pracy,</li>
                <li>stworzenie <b>skryptu rozmowy,</b> gwarantującego skuteczność na określonym wysokim poziomie.</li>
              </ul>
            </div>
            <div className={'text-center ' + styles.workshop}>
              <b>Trzy wersje długości warsztatów:</b>
            </div>
            <div className={'w-75 m-auto d-flex justify-content-around text-center ' + styles.workshopTime}>
              <span>4h</span><span>8h</span><span>16h</span>
            </div>
            <div className="font-corbel">
              <b>Skorzystaj z jednego z kilku warsztatów:</b>
              <ul className="pt-4">
                <li>Wprowadzenie do Skutecznej Sprzedaży w Telefonicznej obsłudze Klienta;</li>
                <li>Zaawansowane techniki wywierania wpływu w Telefonicznej obsłudze Klienta;</li>
                <li>Elementy NLP w telefonicznej obsłudze Klienta;</li>
                <li>Przekonania w sprzedaży, jak ich zmiana, wpływa na skuteczność Klienta;</li>
                <li>Zaawansowane warsztaty z: budowanie profilu idealnego Klienta oraz sposobach na docieranie do niego / budowania relacji z klientem / sondażu potrzeb / prezentacji oferty / rozwiewania obiekcji / finalizacji / zbierania rekomendacji oraz poleceń.</li>
              </ul>
            </div>
            <br/>
            <div className="font-corbel">* Każdy warsztat jest poprzedzony badaniem potrzeb, które jest wliczone w cenę warsztatu</div>
            <div className="font-corbel">** Warsztaty możliwe w formie offline oraz online.</div>
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <div className="row">
              <div className="col-xl-4">
                <Offer
                  title="DORADZTWO INDYWIDUALNE"
                  list={{title: "Możliwości pracy indywidualnej:", items: [
                    'Coaching dla konsultanta;',
                    'Doradztwo dla kadry kierowniczej;',
                    'Mentoring dla Trenera wewnętrznego.'
                  ]}}
                  info="* Możliwość zakontraktowania 1 lub kilku sesji, w zależności od potrzeb oraz rodzaju usługi."
                />
              </div>
              <div className="col-xl-4">
                <Offer
                  title="POPRAWA W ORGANIZACJI"
                  list={{title: "Jeżeli masz problem w organizacji, ale nie potrafisz go nazwać, skorzystaj z poniższych usług:", items: [
                    'Badanie Tajemniczy Klient;',
                    'Diagnoza kultury organizacyjnej;',
                    'Business Model Canvas.'
                    ]}}
                  info="* Napisz, zweryfikujemy, które narzędzie najlepiej sprawdzi się w Twoim biznesie."
                />
              </div>
              <div className="col-xl-4">
                <Offer
                  title="WYCENA"
                  text={[
                    'I teraz pytanie, które masz w swojej głowie i na które szukasz odpowiedzi: ile to Cię będzie kosztowało?',
                    'To zależy... od ilości pracowników, usługi, z której chcesz skorzystać, ilości usług na które zgłosisz zapotrzebowanie. Jestem aktywnym sprzedawcą i myślę kategoriami korzyści, które jestem w stanie dostarczyć Klientowi równocześnie wyznaję zasadę WIN - WIN.'
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <h2 className="text-center text-lighten-rogala">
              Napisz albo zadzwoń
            </h2>
            <h5 className="text-center text-lighten-rogala pt-xl-4">
              Po zbadaniu potrzeb, dopasuję dla Ciebie najlepsze rozwiązanie!
            </h5>
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <Testimonials testimonials={this.state.testimonials} />
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <Numbers />
          </div>
          <div className="w-100 pt-6"></div>
          <div className="col-xl-8 offset-xl-2">
            <ContactForm />
          </div>
        </section>
      </div>
    )
  }
}
