import React from 'react';

import styles from './Numbers.module.scss';
import iconConsultation from "../../pages/Homepage/icon-consultation.svg";
import iconTalk from "../../pages/Homepage/icon-talk.svg";
import iconContact from "../../pages/Homepage/icon-contact.svg";

const Numbers = () => {
  const items = [
    {icon: iconConsultation, value: 0, unite: 'zł', description: 'BEZPŁATNA<br/>KONSULTACJA'},
    {icon: iconTalk, value: 30, unite: 'min', description: 'KRÓTKA<br/>ROZMOWA'},
    {icon: iconContact, value: 24, unite: 'h', description: 'KONTAKT<br/>W CIĄGU 1 DNIA'},
  ]

  return (
    <div className="px-5 row">
      {items.map((item, key) =>
        <div key={key} className="col-12 col-sm-4 py-3">
          <div className="row">
            <div className={`d-flex justify-content-end justify-content-sm-center col-7 col-sm-12 ${styles.numbers}`}>
              <div className="order-2 order-sm-1"><img src={item.icon} alt="" className={styles.image} /></div>
              <div className="order-1 order-sm-2">
                <span className={styles.value}>{item.value}</span>
                <span className={styles.unite}>{item.unite}</span>
              </div>
            </div>
            <div className="text-left col-5 col-sm-12 offset-sm-1 text-sm-center"
                 dangerouslySetInnerHTML={{__html: item.description}}>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Numbers