import React from 'react';
import { Link } from 'react-router-dom';
import { Animated } from "react-animated-css";
import * as objectPath from 'object-path';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'

import styles from './Post.module.scss';
import basePath from '../../pages/Blog/post-example.png';

const Post = (props) => {
  const baseImage = {
    "file": "post-example.png",
    "width": 330,
    "height": 209,
    "mime_type": "image/png",
    "source_url": basePath
  }
  const post = props.post;
  const delay = props.column * 2235;
  const image = objectPath.get(post.image, 'path', baseImage);
  const alt = objectPath.get(post.image, 'alt', '');

  const renderTags = (tags) => {
    if (0 === tags.length) {
      return (<></>)
    }

    return (
      <>
        <FontAwesomeIcon icon={faTags} /> <small>{tags.map((tag) => tag.name).join(', ')}</small>
      </>
    )
  }

  return (
    <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={props.isVisible} animationInDelay={delay}>
      <div className="row pb-5">
        <div className="col-xl-12">
          <Link to={`/blog/${post.slug}`}>
            <div className={'mx-auto ' + styles.image}
                 title={alt}
                 style={{
                   width: image.width,
                   height: image.height,
                   backgroundImage: `url(${image.source_url})`
                }} />
          </Link>
        </div>
        <div className={'col-xl-10 offset-xl-1'}>
          <div className={'py-3 ' + styles.title}>
            <Link to={`/blog/${post.slug}`} dangerouslySetInnerHTML={{__html: post.title}} />
          </div>
          <div className={styles.content} dangerouslySetInnerHTML={{__html: post.content}}></div>
          <div className="pt-1">{renderTags(post.tags)}</div>
        </div>
      </div>
    </Animated>
  )
}

export default Post;