import React, { useState } from 'react';
import { Checkbox } from 'pretty-checkbox-react';

import styles from './Form.module.scss';
import '@djthoms/pretty-checkbox';

const Form = (props) => {
  const isPopUp = props.close !== undefined;
  const enableFields = props.fields || [];
  const isBlue = (props.theme || 'default') === 'blue';
  let contactForm = isBlue ? styles.containerBlue : styles.container;
  let contactFormHeader = isBlue ? styles.headerBlue : styles.header;
  let contactFormBody = isBlue ? styles.bodyBlue : styles.body;
  const contactFormSendContainer = isBlue ? styles.sendContainerBlue : styles.sendContainer;
  const sendBorder = isBlue ? styles.borderBlue : styles.border;
  const sendLine = isBlue ? styles.lineBlue : styles.line;
  if (isPopUp) {
    contactFormHeader = `${contactFormHeader} ${styles.radiusTop}`;
    contactFormBody = `${contactFormBody} ${styles.radiusBottom}`;
    contactForm = `${contactForm} ${styles.radiusTop} ${styles.radiusBottom}`;
  }

  const READY = 'ready';
  const PENDING = 'pending';
  const SUCCEEDED = 'succeeded';
  const FAILED = 'failed';

  const [mailStatus, setMailStatus] = useState(READY)
  const [fields, setField] = useState({});
  const [tips, setTips] = useState(false)
  const [newsletter, setNewsletter] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    setMailStatus(PENDING);

    setTimeout(() => {
      props.handle(fields, tips, newsletter)
        .then(() => {
          setMailStatus(SUCCEEDED);
        })
        .catch((e) => {
          setMailStatus(FAILED)
        })
        .then(() => {
          setTimeout(() => {
            props.complete === undefined
              ? setMailStatus(READY)
              : (() => props.complete(fields))()
          }, (props.completeDelay || 1) * 1000);
        })
    }, 1500)
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setField({
      ...fields,
      [name]: value
    })
  }

  const renderField = (name, label, required) => {
    const fieldId = `contact-form-${name}`;
    required = required !== undefined ? required : true;
    label = required ? `${label}*` : label;

    return (
      <div className="form-group">
        <label htmlFor={fieldId}>{label}</label>
        <input type="text"
               className="form-control"
               id={fieldId}
               name={name}
               {...(required && {required: true})}
               value={fields[name]}
               onChange={handleInputChange} />
      </div>
    )
  }

  const renderNewsletter = () => {
    return (
      <div className="form-group">
        <Checkbox onClick={() => {setNewsletter(!newsletter)}}
                  checked={newsletter}
                  animation="smooth"
                  color="primary-o">
        </Checkbox>
        <span onClick={() => {setNewsletter(!newsletter)}}>Zapisuje się na newsletter</span>
      </div>
    )
  }

  const renderTips = (text) => {
    return (
      <div className="form-group">
        <Checkbox onClick={() => {setTips(!tips)}}
                  checked={tips}
                  animation="smooth"
                  color="primary-o"
                  required>
        </Checkbox>
        <span onClick={() => {setTips(!tips)}} dangerouslySetInnerHTML={{__html: text}}></span>
      </div>
    )
  }

  const renderForm = enableFields => {
    return (
      <form className="w-100" onSubmit={handleSubmit}>
        {enableFields.includes('name') ? renderField('name', 'imię i nazwisko') : ''}
        {enableFields.includes('phone') ? renderField('phone', 'telefon') : ''}
        {enableFields.includes('email') ? renderField('email', 'e-mail') : ''}
        {props.tips !== undefined ? renderTips(props.tips) : ''}
        {enableFields.includes('newsletter') ? renderNewsletter() : ''}
        <button type="form-check-label" className="btn btn-block btn-rogala">{props.submit}</button>
      </form>
    )
  }

  const renderPending = () => {
    return (
    <div className={contactFormSendContainer}>
      <div className={styles.box}>
        <div className={`${sendBorder} ${styles.borderOne}`}></div>
        <div className={`${sendBorder} ${styles.borderTwo}`}></div>
        <div className={`${sendBorder} ${styles.borderThree}`}></div>
        <div className={`${sendBorder} ${styles.borderFour}`}></div>

        <div className={`${sendLine} ${styles.lineOne}`}></div>
        <div className={`${sendLine} ${styles.lineTwo}`}></div>
        <div className={`${sendLine} ${styles.lineThree}`}></div>
      </div>
    </div>
    )
  }

  const renderSucceeded = () => {
    return (
      <div className={styles.successCheckmark}>
        <div className={styles.checkIcon}>
          <span className={`${styles.iconLine} ${styles.lineTip}`}></span>
          <span className={`${styles.iconLine} ${styles.lineLong}`}></span>
          <div className={styles.iconCircle}></div>
          <div className={styles.iconFix}></div>
        </div>
      </div>
    )
  }

  const renderFailed = () => {
    return (
      <div className={styles.failedCheckmark}>
        <div className={styles.failedCheckIcon}>
          <span className={`${styles.failedIconLine} ${styles.failedLineLongInvert}`}></span>
          <span className={`${styles.failedIconLine} ${styles.failedLineLong}`}></span>
          <div className={styles.failedIconCircle}></div>
          <div className={styles.failedIconFix}></div>
        </div>
      </div>
    )
  }

  return (
    <div className={`w-100 mx-auto ${contactForm}`}>
      { isPopUp ? <div className="float-right p-2">{ props.close }</div> : '' }
      <div className={`py-5 px-5 px-xl-0 text-center font-corbel ${contactFormHeader}`}>
        <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{__html: props.title}}></div>
      </div>
      <div className={`font-corbel ${contactFormBody}`}>
        <div className={`w-85 w-xl-35 mx-auto d-flex align-items-center ${styles.form}`}>
            {mailStatus === READY ? renderForm(enableFields) : null}
            {mailStatus === PENDING ? renderPending() : null}
            {mailStatus === SUCCEEDED ? renderSucceeded() : null}
            {mailStatus === FAILED ? renderFailed() : null}
        </div>
      </div>
    </div>
  );
}

export default Form