import React from 'react';

import styles from './Offer.module.scss';

const Offer = (props) => {
  const renderList = (title, list) => <div>
    <b>{title}</b>
    <ul>
      {list.map((item, key) => <li key={key}>{item}</li>)}
    </ul>
  </div>

  const renderText = (text) => <div dangerouslySetInnerHTML={{__html: text.join('<br/><br/>')}}></div>

  const renderInfo = (info) => <div className={'pt-xl-2'}>{info}</div>

  return (
    <div className={`pt-5 pt-xl-0 font-corbel ${styles.offer}`}>
      <div className={`text-center text-xl-left ${styles.title}`}>{props.title}</div>
      <hr className={'my-xl-4 ml-xl-0 mr-xl-auto text-light-rogala bg-light-rogala ' + styles.line} />
      {props.list ? renderList(props.list.title, props.list.items) : null}
      {props.text ? renderText(props.text) : null}
      {props.info ? renderInfo(props.info) : null}
    </div>
  )
}

export default Offer;