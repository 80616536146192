import React from 'react';

import styles from './EffectivenessPillar.module.scss';

const EffectivenessPillar = (props) => {
  return (
    <div className={'row'}>
      <div className={'col-xl-4 ' + styles.number}>{props.number}</div>
      <div className={'col-xl-7 ' + styles.title}>{props.title}</div>
      <div className={'w-100'}></div>
      <div className={'offset-xl-4 col-xl-7 text-left ' + styles.list}>
        <div className={'pt-xl-3 pb-xl-4 text-left'}>{props.list.title}</div>
        {props.list.items.map((item, position) =>
          <div key={position} className={'d-flex flex-row justify-content-start align-items-start ' + styles.itemText}>
            <div className={`text-white bg-lighten-rogala ${styles.itemNumber}`}>{position + 1}</div>
            <div>{item}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EffectivenessPillar