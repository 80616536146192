import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import "./main.scss"

import Header from "./components/Header/Header";
import Homepage from "./pages/Homepage/Homepage";
import ForBusiness from "./pages/ForBusiness/ForBusiness";
import Blog from "./pages/Blog/Blog";
import BlogPost from "./pages/BlogPost/BlogPost";
import NotFound from "./pages/NotFound/NotFound";
import Footer from "./components/Footer/Footer";
import ToDoList from "./components/ToDoList/ToDoList";
import ScrollUpButton from "react-scroll-up-button";
import {faArrowCircleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToDoList delay="10" />
      <Header />
      <main>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/for-business" component={ForBusiness} />
          <Route exact path="/consultation" component={Homepage} />
          <Route exact path="/contact" component={Homepage} />
          <Route exact path="/blog/:slug" component={BlogPost} />
          <Route exact path="/blog" component={Blog} />
          <Route component={NotFound} />
        </Switch>
      </main>
      <Footer />
      <ScrollUpButton ContainerClassName="backToTop" TransitionClassName="backToTopTransition">
        <FontAwesomeIcon icon={faArrowCircleUp} size="3x" />
      </ScrollUpButton>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
