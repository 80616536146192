import ApiHandler from "./ApiHandler";

export default class TestimonialsHandler {
  constructor() {
    this.api = new ApiHandler();
  }

  async items() {
    return await this.api.getTestimonials();
  }
}