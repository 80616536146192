import React, { useState } from 'react';
import { saveAs } from 'file-saver'

import { Modal, ModalBody } from 'reactstrap';
import Form from "../Form/Form";
import styles from './ToDoList.module.scss';
import ToDoListHandler from "../../services/ToDoListHandler";


const ToDoList = (props) => {
  const STORAGE_KEY = 'to-do-list-close';
  const [modal, setModal] = useState(false);
  const close = () => {
    setModal(false);
  }

  const handleSubmit = (fields, tips, withNewsletter) => {
    return (new ToDoListHandler()).post(fields, withNewsletter)
  }

  const complete = (fields) => {
    saveAs('/todo.pdf', 'E-book_Damian_Rogala.pdf');
    close();
  }

  if (sessionStorage.getItem(STORAGE_KEY) === null) {
    sessionStorage.setItem(STORAGE_KEY, "true");
    setTimeout(() => {
      setModal(true);
    }, (props.delay || 10) * 1000)
  }

  return (
    <div>
      <Modal isOpen={modal} size="lg" contentClassName={styles.content}>
        <ModalBody className={styles.body}>
          <Form theme="blue"
            close={<button className="close" onClick={close}>&times;</button>}
            title="Wypełnij formularz i pobierz checklistę,<br />dzięki której przygotujesz siebie i swój zespół do skutecznego cold callingu!"
            fields={['name', 'email', 'phone', 'newsletter']}
            tips={`Pobierając <u>wyrażam zgodę</u> na przetwarzanie moich danych osobowych w zakresie niezbędnym dla realizacji zlecenia oraz <b>zapoznałem/am</b> się z treścią <a href="/polityka.pdf" class="${styles.link}" target="_blank" rel=" noreferrer">polityki prywatności</a> oraz <u>akceptuje jej treść.</u>*`}
            submit="Pobierz"
            handle={handleSubmit}
            complete={complete}
            completeDelay={1.2}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ToDoList