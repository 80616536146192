import * as objectPath from "object-path";

export default class ApiHandler {
  API_URL = 'https://damianrogala.com/wp-blog/wp-json/wp/v2';

  async fetchData(url) {
    let total = 0;
    let totalPages = 0;
    let data = await fetch(url)
      .then((response) => {
        if (response.status > 400) {
          throw new Error();
        }

        total = response.headers.get('x-wp-total');
        totalPages = response.headers.get('x-wp-totalpages');

        return response.json();
      })
      .catch((e) => {
        return []
      });

    return {
      total,
      totalPages,
      data
    }
  }

  async getTestimonials() {
    const url = new URL(`${this.API_URL}/testimonials`);
    const response = await this.fetchData(url);

    const items = response.data.map(async (item) => {
      return {
        id: item.id,
        title: item.title.rendered,
        slug: item.slug,
        content: item.content.rendered,
      }
    });

    return await Promise.all(items);
  }

  async getTags() {
    const url = new URL(`${this.API_URL}/tags`);
    url.search = new URLSearchParams({per_page: 5, orderBy: 'count'}).toString()
    const response = await this.fetchData(url);

    const items = response.data.map(async (item) => {
      return {
        id: item.id,
        name: item.name,
        slug: item.slug,
        count: item.count,
        description: item.description
      }
    })

    return await Promise.all(items);
  }

  async getTagByIds(ids) {
    const basePath = `${this.API_URL}/tags`;

    const items = ids.map(async (id) => {
      const url = new URL(`${basePath}/${id}`);
      const response = await this.fetchData(url)
      let name = '';
      let slug = '';
      let count = 0;
      let description = '';

      if (!objectPath.empty(response.data)) {
        name = response.data.name;
        slug = response.data.slug;
        count = response.data.count;
        description = response.data.description;
      }

      return {
        id,
        name,
        slug,
        count,
        description
      }
    });

    const tags = await Promise.all(items);

    return tags.filter((tag) => tag.name.length > 0);
  }

  async getPosts(page, perPage, tags = [], categories = [], excludeIds = []) {
    const url = new URL(`${this.API_URL}/posts`);
    let searchParameters = {
      per_page: perPage,
      page: page,
    }
    if (tags.length > 0) {
      searchParameters.tags = tags.join(',')
    }
    if (categories.length > 0) {
      searchParameters.categories = categories.join(',');
    }
    if (excludeIds.length > 0) {
      searchParameters.exclude = excludeIds.join(',');
    }
    url.search = new URLSearchParams(searchParameters).toString();
    const response = await this.fetchData(url);

    const items = response.data.map(async (item) => {
      const imagePath = objectPath.get(item, ['_links', 'wp:featuredmedia', 0, 'href']);
      const tagIds = objectPath.get(item, 'tags', []);
      let image = {};
      let tags = [];
      if (imagePath) {
        image = await this.getImage(imagePath);
      }
      if (tagIds.length > 0) {
        tags = await this.getTagByIds(tagIds);
      }

      return {
        id: item.id,
        title: item.title.rendered,
        slug: item.slug,
        content: item.excerpt.rendered,
        image,
        tags
      }
    });

    return await Promise.all(items);
  }

  async getImage(path) {
    const sourcePaths = [
      'media_details.sizes.large',
      'media_details.sizes.medium_large',
      'media_details.sizes.medium',
      'media_details.sizes.full',
    ];

    const response = await this.fetchData(new URL(path));
    let item = {
      postId: response.data.post,
      alt: objectPath.get(response.data, 'alt_text', '')
    }
    for (let index in sourcePaths) {
      let path = sourcePaths[index];
      if (objectPath.has(response.data, path)) {
        item.path = objectPath.get(response.data, path);

        return item;
      }
    }

    return item;
  }

  async getPost(slug) {
    const url = new URL(`${this.API_URL}/posts`);
    url.search = new URLSearchParams({slug}).toString();
    const response = await this.fetchData(url);
    if (0 === response.data.length) {
      return;
    }
    const post = response.data.shift();

    return {
      id: post.id,
      title: post.title.rendered,
      slug: post.slug,
      content: post.content.rendered,
    }
  }
}
