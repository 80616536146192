import Form from "../Form/Form";
import styles from "./ContactForm.module.scss";
import * as Scroll from "react-scroll";
import ContactHandler from "../../services/ContactHandler";

const ContactForm = (props) => {
  const Element  = Scroll.Element;
  const handleSubmit = (fields, tips, withNewsletter) => {
    return (new ContactHandler()).post(fields, withNewsletter)
  }
  const theme= props.theme || 'default';
  const link = theme === 'blue' ? styles.linkBlue : styles.link;

  return (
    <>
      <Element name="ContactForm"></Element>
      <Form
        title="BEZPŁATNA KONSULTACJA Z COLD CALLINGU!<br/>Zostaw kontakt, a ja oddzwonię w ciągu 24 godzin:"
        fields={['name', 'email', 'phone', 'newsletter']}
        tips={`Wysyłając zapytanie oświadczam, że <b>zapoznałem/am</b> się z treścią <a href="/regulamin.pdf" class=${link} target="_blank" rel="noreferrer">regulaminu</a> oraz <a href="/polityka.pdf" class=${link} target="_blank" rel="noreferrer">polityką prywatności</a> oraz <u>akceptuje ich treść.</u>*`}
        submit="WYŚLIJ!"
        handle={handleSubmit}
        completeDelay={0.99}
        theme={theme}
      />
    </>
  )
}

export default ContactForm;